import React, { Component } from 'react';
import HomeSlides from "./HomeSlides";
import HomeList from "./HomeList";
import ClientSlides from "./ClientSlides";

class HomeComponent extends Component {

    render() {
        return (
            <div>
                <HomeSlides />
                <HomeList />
                <ClientSlides />
            </div>
        );
    }
}

export default HomeComponent;