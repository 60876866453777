import '../../../../styles/tailwind.css';

const products = [
    {
        id: 1,
        name: 'RGE-R',
        href: '/RGER',
        description: 'Residual current relay',
        imageSrc: '/images/products/Protection/RGER/RGER_R1.jpg',
        imageAlt: 'RGER',
    },
    {
        id: 2,
        name: 'RGE-RL',
        href: '/RGERL',
        description: 'Residual current relay',
        imageSrc: '/images/products/Protection/RGERL/RGERL_R1.jpg',
        imageAlt: 'CVM-C4',
    },
    {
        id: 3,
        name: 'RGU-10',
        href: '/RGU10',
        description: 'Residual current relay',
        imageSrc: '/images/products/Protection/RGU10/RGU10_R1.jpg',
        imageAlt: 'RGU10',
    },
    {
        id: 4,
        name: 'WRU-10-MT',
        href: '/WRU10',
        description: 'Power analyzer',
        imageSrc: '/images/products/Protection/WRU10/WRU10_R1.jpg',
        imageAlt: 'WRU10',
    },
]

export default function Protection() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{textAlign: "center"}}>Measuring and Control</h1>
                <div className="my-6 text-center text-gray-600">
                    Digital instrumentation devices are implemented to exhibit electrical or process data swiftly for monitoring and control, commonly within industrial environments. This data is valuable for promptly detecting potential alarms triggered by control values, providing real-time notifications of any malfunctions in industrial machinery or control systems. The displayed information is numeric and directly corresponds to the measured parameter, facilitating integration with SCADA systems, provided they support integrated communications.
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 py-20">
                    {products.map((product) => (
                        <a key={product.id} href={product.href} className="group">
                            <div className="aspect-w-7 aspect-h-5 bg-gray-200 rounded-lg overflow-hidden">
                                <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="object-cover w-full h-full group-hover:opacity-75"
                                />
                            </div>
                            <h3 className="mt-4 text-lg text-gray-900">{product.name}</h3>
                            <p className="mt-1 text-sm font-medium text-gray-700">{product.description}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

