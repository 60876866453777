import '../../../styles/tailwind.css';

const products = [
    {
        id: 4,
        name: 'Power Factor Correction and Harmonic Filtering',
        href: '/Correction',
        description: 'Avoiding P.F Penalties from the utility and protecting Electrical system ',
        imageSrc: '/images/categories/pfcorrection.jpg',
        imageAlt: 'Power Factor Correction and Harmonic Filtering',
    },
    {
        id: 1,
        name: 'Power Analyzers',
        href: '/Analyzers',
        description: 'Real-Time Electrical Parametrization and Controlling of the Devices',
        imageSrc: '/images/products/Analyzers/analyzermain.jpg',
        imageAlt: 'Analyzers',
    },
    {
        id: 2,
        name: 'Metering',
        href: '/Metering',
        description: 'Energy Consumption metering through smart energy meters and advanced softwares',
        imageSrc: '/images/categories/metering.jpg',
        imageAlt: 'Metering',
    },
    {
        id: 3,
        name: 'Protection and Control',
        href: '/Protection',
        description: 'Avoiding mishap to human life or Device due to abnormal currents and voltage fluctuations',
        imageSrc: '/images/categories/protection.jpg',
        imageAlt: 'Protection and Control',
    },

    {
        id: 5,
        name: 'Software and Services',
        href: '/',
        description: 'Advanced SCADA software for seamless power management, optimizing energy efficiency and system control.',
        imageSrc: 'images/products/Software/PSS.jpg',
        imageAlt: 'Renewable Energies',
    },
    {
        id: 6,
        name: 'Software and Services',
        href: '/',
        description: 'On Premise (USB Dongle) Softwares and Cloud based remote softwares',
        imageSrc: '/images/categories/metering.jpg',
        imageAlt: 'Software and Services',
    },
    {
        id: 7,
        name: 'Software and Services',
        href: '/',
        description: 'On Premise (USB Dongle) Softwares and Cloud based remote softwares',
        imageSrc: '/images/categories/metering.jpg',
        imageAlt: 'Software and Services',
    },
    {
        id: 8,
        name: 'Software and Services',
        href: '/',
        description: 'On Premise (USB Dongle) Softwares and Cloud based remote softwares',
        imageSrc: '/images/categories/metering.jpg',
        imageAlt: 'Software and Services',
    },
]

export default function CircutorProductList() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>

                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                    {products.map((product) => (
                        <a key={product.id} href={product.href} className="group">
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                />
                            </div>
                            <h3 className="mt-4 text-lg text-gray-900">{product.name}</h3>
                            <p className="mt-1 text-sm font-medium text-gray-700">{product.description}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
