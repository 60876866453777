let tableData;
const product = {
    name: 'computer SMART III 14',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Power Factor Correction', href: '/Correction' },
    ],
    images: [
        {
            src: '/images/products/Correction/COMP-MAX-SMART/COMP-MAX-SMART_R.jpg',
            alt: 'COMPMAXSMART',
        },
    ],
    description:
        'Measurement with three current transformers guarantees an analogue reading of the company meter. The Computer SMART III reactive energy regulator is the only regulator on the market that offers the possibility of using 3 measurement transformers in addition to the conventional method of measuring with a single current transformer, as well as providing the functions of an integral power analyzer and controlling residual leakage currents (WG series current transformers).\n' +
        'Computer SMART III is a regulator that ensures excellent preventive maintenance by means of programming its alarms and the options for testing the capacitor status, offering maximum supervision and safety of your compensation unit.',

    highlights: [
        'Changing from 1 to 3 transformers in the following cases:',
        '- Changes in reactive energy penalties',
        '- Changes in consumption habits',
        '- Significant imbalances in the system',
        '- Replacing the regulator of any capacitor bank',
        'Perfect for installations with up to 4 objective cos φ, since it can adapt to any compensation need (different time periods).',
        'It can be used with Medium Voltage compensation units.'
    ],

    const: tableData = [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "14 ... 18 VA" },
        { label: "Frequency", value: "50 … 60 Hz" },
        { label: "Nominal voltage", value: "100 ... 400 V ~" },
    ],

    secondTableData: [
        { label: "Size (mm) width x height x depth", value: "144 x 144 x 71 (mm)" },
        { label: "Envelope", value: "Plastic V0 self-extinguishing" },
        { label: "Fastening", value: "Panel" },
        { label: "Weight (kg)", value: "0,619" },
    ],

    thirdTableData: [
        { label: "Protection class", value: "IP 51 (Front), IP 31 (unmounted)" },
        { label: "Relative humidity (without condensation)", value: "5 … 95%" },
        { label: "Storage temperature", value: "-20 … +70 ºC" },
        { label: "Working temperature", value: "-10 … +55 ºC" },
    ],

    fourthTableData: [
        { label: "Nominal current (In)", value: ".../5A ó .../1A" },
        { label: "Phase current measuring range", value: "1 … 120 % In" },
        { label: "Minimum current measurement", value: "50 mA" },
    ],

    fifthTableData: [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Sampling frequency", value: "45 … 65 Hz" },
        { label: "Input impedance", value: "660 kΩ" },
        { label: "Voltage measuring range", value: "45 … 65 Hz" },
        { label: "Nominal voltage", value: "230 V Ph-N, 400 V Ph-Ph" },
        { label: "Minimum measurement voltage (Vstart)", value: "20 V F-N, 35 V F-F" },
    ],

    sixthTableData: [
        { label: "Fieldbus (ModBus)", value: "RS-485" },
        { label: "Stop bits (ModBus)", value: "1-2" },
        { label: "Parity", value: "without, even, odd" },
        { label: "Protocol", value: "Modbus RTU" },
        { label: "Speed", value: "9600-19200" },
    ],

    seventhTableData: [
        { label: "Electrical safety, Maximum height (m)", value: "2000" },
        { label: "Standards", value: "IEC 61010, IEC 61000-6-2, IEC 61000-6-4, Medidas conforme a : IEC 61557-12" },

    ],

    eighthTableData: [
        { label: "LED", value: "4 LED" },
        { label: "Keyboard", value: "Capacitive, 5 keys" },
        { label: "Display type", value: "LCD Custom COG" },

    ],

    ninthTableData: [
        { label: "Input/output insulation", value: "Optoisolated" },
        { label: "Quantity", value: "2" },
        { label: "Type", value: "Potential-free contact" },
    ],

    tenthTableData: [
        { label: "Secondary nominal current", value: "0,003 A" },
        { label: "Minimum current measurement (Istart)", value: "10 mA" },
        { label: "Measurement range", value: "0,01 … 1,5 A" },
    ],
    eleventhTableData:[
        { label: "Quantity", value: "16 (14 salidas, 1 ventilador, 1 alarma)" },
        { label: "Maximum current", value: "1A" },
        { label: "Maximum open contact voltage", value: "1 kV" },
        { label: "Electrical life", value: "30 x 103 ciclos" },
        { label: "Mechanical life", value: "5 x 106 Cycles" },
        { label: "Maximum switching capacity", value: "2500 VA" },
    ],
    twelfthTableData:[
        { label: "Quantity", value: "2" },
        { label: "Type", value: "NPN" },
        { label: "Maximum current", value: "50 mA" },
        { label: "Maximum voltage", value: "24 Vcc" },
    ],
    thirteenthTableData:[
        { label: "Phase current measurement", value: "0.5% ± 1 digit" },
        { label: "Reactive energy measurement (kvarh)", value: "Class 1" },
        { label: "Reactive power measurement (kvar)", value: "1% ± 2 digit" },
        { label: "Active energy measurement (kWh)", value: "Class 1" },
        { label: "Active power measurement (kW)", value: "0.5% ± 2 digits" },
        { label: "Phase voltage measurement", value: "0.5% ± 1 digit" },
    ]

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function COMPMAXSMART() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">computer SMART III 14</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">computer SMART III 14, Power factor regulator</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: R13864.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=R13864." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASUREMENT CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASUREMENT CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">DIGITAL INPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">LEAKAGE CURRENT MEASUREMENT (ID)</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL RELAY OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL TRANSISTOR OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.twelfthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.twelfthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirteenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirteenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
