import '../styles/tailwind.css';

const products = [
    {
        id: 1,
        name: 'Circutor',
        href: '/Circutor',
        description: 'Driving energy efficiency for a greener future',
        imageSrc: '/images/company/circutor1.jpg',
        imageAlt: 'circutor',
    },
    {
        id: 2,
        name: 'Industrial Trading & Engineering Corporation',
        href: '/Circutor',
        description: 'Precision weaving solutions from Tsudakoma for diverse fabric needs',
        imageSrc: '/images/company/tsudakoma1.png',
        imageAlt: 'Tsudakoma',
    },
    {
        id: 3,
        name: 'Farm360',
        href: '/Circutor',
        description: 'High-fat milk production from buffaloes and holstein friesian cows',
        imageSrc: '/images/categories/farm.jpg',
        imageAlt: 'Protection and Control',
    },
    {
        id: 4,
        name: 'Organics360',
        href: '/Circutor',
        description: 'Top-quality vermicompost fertilizer for sustainable agriculture',
        imageSrc: '/images/categories/capacitors.png',
        imageAlt: 'Power Factor Correction and Harmonic Filtering',
    },
]

export default function HomeList() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>

                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">     
                    {products.map((product) => (
                        <a key={product.id} href={product.href} className="group">
                            <div className="aspect-w-7 aspect-h-5 w-full overflow-hidden rounded-lg bg-gray-200">
                                <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                />
                            </div>
                            <h3 className="mt-4 text-lg text-gray-900">{product.name}</h3>
                            <p className="mt-1 text-sm font-medium text-gray-700">{product.description}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
