import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeComponent from "./components/HomeComponent";
import CircutorComponent from "./components/subCompanies/circutor/CircutorComponent";

// Analyzers
import Analyzers from "./components/subCompanies/circutor/categories/Analyzers";
import CVMC10 from "./components/subCompanies/circutor/categories/analyzers/CVMC10";
import CVMC4 from "./components/subCompanies/circutor/categories/analyzers/CVMC4";
import CVMA1500 from "./components/subCompanies/circutor/categories/analyzers/CVMA1500";
import CVME3 from "./components/subCompanies/circutor/categories/analyzers/CVME3";
import CVMB100 from "./components/subCompanies/circutor/categories/analyzers/CVMB100";
import LineEDS from "./components/subCompanies/circutor/categories/analyzers/LineEDS";
import DCP96AAC from "./components/subCompanies/circutor/categories/analyzers/DCP96AAC";
import DCP96VAC from "./components/subCompanies/circutor/categories/analyzers/DCP96VAC";
import myebox from "./components/subCompanies/circutor/categories/analyzers/myebox";

// Correction
import Correction from "./components/subCompanies/circutor/categories/Correction";
import COMPC6 from "./components/subCompanies/circutor/categories/correction/COMPC6";
import COMPC12 from "./components/subCompanies/circutor/categories/correction/COMPC12";
import COMPMAX6 from "./components/subCompanies/circutor/categories/correction/COMPMAX6";
import COMPMAX12 from "./components/subCompanies/circutor/categories/correction/COMPMAX12";
import COMPMAXSMART from "./components/subCompanies/circutor/categories/correction/COMPMAXSMART";
import COMPSMART312 from "./components/subCompanies/circutor/categories/correction/COMPSMART312";
import CLZHD4425 from "./components/subCompanies/circutor/categories/correction/CLZHD4425";
import AFQM4W30M400 from "./components/subCompanies/circutor/categories/correction/AFQM4W30M400";
import AFQM4W75M400 from "./components/subCompanies/circutor/categories/correction/AFQM4W75M400";
import AFQM4W100M400 from "./components/subCompanies/circutor/categories/correction/AFQM4W100M400";
import SVGM4W20M400 from "./components/subCompanies/circutor/categories/correction/SVGM4W20M400";
import SVGM4W50M400 from "./components/subCompanies/circutor/categories/correction/SVGM4W50M400";
import SVGM4W69M400 from "./components/subCompanies/circutor/categories/correction/SVGM4W69M400";

// Protection
import Protection from "./components/subCompanies/circutor/categories/Protection";
import RGER from "./components/subCompanies/circutor/categories/protection/RGER";
import RGERL from "./components/subCompanies/circutor/categories/protection/RGERL";
import RGU10 from "./components/subCompanies/circutor/categories/protection/RGU10";
import WRU10 from "./components/subCompanies/circutor/categories/protection/WRU10";

// Metering
import Metering from "./components/subCompanies/circutor/categories/Metering";
import CEMC20 from "./components/subCompanies/circutor/categories/metering/CEMC20";
import CEMC20M from "./components/subCompanies/circutor/categories/metering/CEMC20M";
import CEMC31 from "./components/subCompanies/circutor/categories/metering/CEMC31";
import CEMC6 from "./components/subCompanies/circutor/categories/metering/CEMC6";
import CIRWATTB410D from "./components/subCompanies/circutor/categories/metering/CIRWATTB410D";

function App() {
    return (
        <div className="app-container w-full">
            <Router>
                <Header />
                <div className="mx-auto w-full">
                    <Routes>
                        <Route path="/" element={<HomeComponent />} />
                        <Route path="/Circutor" element={<CircutorComponent />} />
                        <Route path="/Analyzers" element={<Analyzers />} />
                        <Route path="/CVMC10" element={<CVMC10 />} />
                        <Route path="/CVMC4" element={<CVMC4 />} />
                        <Route path="/CVMA1500" element={<CVMA1500 />} />
                        <Route path="/CVME3" element={<CVME3 />} />
                        <Route path="/CVMB100" element={<CVMB100 />} />
                        <Route path="/LineEDS" element={<LineEDS />} />
                        <Route path="/myebox" element={<myebox />} />
                        <Route path="/DCP96AAC" element={<DCP96AAC />} />
                        <Route path="/DCP96VAC" element={<DCP96VAC />} />
                        <Route path="/Correction" element={<Correction />} />
                        <Route path="/AFQM4W30M400" element={<AFQM4W30M400 />} />
                        <Route path="/AFQM4W75M400" element={<AFQM4W75M400 />} />
                        <Route path="/AFQM4W100M400" element={<AFQM4W100M400 />} />
                        <Route path="/SVGM4W20M400" element={<SVGM4W20M400 />} />
                        <Route path="/SVGM4W50M400" element={<SVGM4W50M400 />} />
                        <Route path="/SVGM4W69M400" element={<SVGM4W69M400 />} />
                        <Route path="/COMPC6" element={<COMPC6 />} />
                        <Route path="/COMPC12" element={<COMPC12 />} />
                        <Route path="/COMPMAX6" element={<COMPMAX6 />} />
                        <Route path="/COMPMAX12" element={<COMPMAX12 />} />
                        <Route path="/COMPMAXSMART" element={<COMPMAXSMART />} />
                        <Route path="/COMPSMART312" element={<COMPSMART312 />} />
                        <Route path="/CLZHD4425" element={<CLZHD4425 />} />
                        <Route path="/Protection" element={<Protection />} />
                        <Route path="/RGER" element={<RGER />} />
                        <Route path="/RGERL" element={<RGERL />} />
                        <Route path="/RGU10" element={<RGU10 />} />
                        <Route path="/WRU10" element={<WRU10 />} />
                        <Route path="/Metering" element={<Metering />} />
                        <Route path="/CEMC20" element={<CEMC20 />} />
                        <Route path="/CEMC20M" element={<CEMC20M />} />
                        <Route path="/CEMC31" element={<CEMC31 />} />
                        <Route path="/CEMC6" element={<CEMC6 />} />
                        <Route path="/CIRWATTB410D" element={<CIRWATTB410D />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
