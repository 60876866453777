import React, { useState, useEffect } from 'react';
import './ClientSlides.css';

const slidesData = [
    {
        image: 'images/company/lightblue.svg',
        title: 'Our Trusted Customers',
        description: 'Partnering with industry leaders to deliver excellence.'
    },
    {
        image: 'images/company/lightblue2.svg',
        title: 'Our Trusted Customers',
        description: 'Partnering with industry leaders to deliver excellence.'
    },
    {
        image: 'images/company/lightblue3.svg',
        title: 'Our Partners in Switchgear',
        description: 'Collaborating with top-tier switchgear companies to ensure superior product delivery and industry-leading solutions.'
    },
    {
        image: 'images/company/lightblue4.svg',
        title: 'Our Partners in Switchgear',
        description: 'Collaborating with top-tier switchgear companies to ensure superior product delivery and industry-leading solutions.'
    }
];

const ClientSlides = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1));
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval);
    }, []);

    const goToPrevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slidesData.length - 1 : currentSlide - 1);
    };

    const goToNextSlide = () => {
        setCurrentSlide(currentSlide === slidesData.length - 1 ? 0 : currentSlide + 1);
    };

    return (
        <div className="clientslides">
            <div className="arrow left-arrow" onClick={goToPrevSlide}>&#9664;</div>
            {slidesData.map((slide, index) => (
                <div key={index} className={index === currentSlide ? 'slide active' : 'slide'}>
                    <img src={slide.image} alt={slide.title} />
                    <div className="text">
                        <h2>{slide.title}</h2>
                        <p>{slide.description}</p>
                    </div>
                </div>
            ))}
            <div className="arrow right-arrow" onClick={goToNextSlide}>&#9654;</div>
        </div>
    );
};

export default ClientSlides;
