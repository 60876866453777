let tableData;
const product = {
    name: 'CVM-C10',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Power Analyzers', href: '/Analyzers' },
    ],
    images: [
        {
            src: '/images/products/Analyzers/CVM-C10/CVM-C10_R.jpg',
            alt: 'CVM',
        },
    ],
    description:
        'The CVM-C10 is a panel mounted (96 x 96 mm) power analyzer that records energy values. Compact and versatile, with 4-quadrant measurement (consumption and generation). Suitable for Medium or Low voltage installations, in both 3 or 4-wire three-phase circuits,\n' +
        'two-phase circuits with or without neutral, single-phase circuits or ARON connections.',
    highlights: [
        'Backlit touch-screen (capacitive)',
        'Analogue display of instantaneous parameters (power, maximum power reached and cos φ or PF)',
        'Backlit display',
        'Alarm LED indicator',
        'Tariff cost',
        'Operating hour indicator for preventive maintenance',
    ],
    details: [
        'Record the energy consumption from three different sources: network, generator set or photovoltaic energy generation system.',
        'Generation of an impulse signal associated with the cost, kgCO2 emissions or savings, according to the consumption or generation of energy.',
        'Selection of tariffs with digital inputs. Perfect to calculate costs in three different work shifts.',
        'Programs alarms on any instantaneous parameter measured or calculated. Configurable parameters: Low/High, hysteresis (%), NO/NC, connection/disconnection delay and interlocking.',
    ],
    const: tableData = [
        { id: 1, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 2, label: 'Consumption', value: '4 ... 6 VA' },
        { id: 3, label: 'Frequency', value: '50 ... 60 Hz' },
        { id: 4, label: 'Nominal voltage', value: '95 ... 240 V ~ ± 10%' },
    ],

    secondTableData: [
        { id: 1, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 2, label: 'Consumption', value: '2 ... 6 W' },
        { id: 3, label: 'Nominal voltage', value: '105 ... 272 Vdc ± 10%' },
    ],

    thirdTableData: [
        { id: 1, label: 'Size (mm) width x height x depth', value: '96 x 96 x 60.9 (mm)' },
        { id: 2, label: 'Envelope', value: 'Self-extinguishing V0 plastic' },
        { id: 3, label: 'Fastening', value: 'Panel' },
        { id: 4, label: 'Weight (kg)', value: '0,322' },
    ],

    fourthTableData: [
        { id: 1, label: 'Correction class', value: 'IP 51 (Front), IP 64 (with accessory), IP 21 (rear) ' },
        { id: 2, label: 'Relative humidity (without condensation)', value: '5 ... 95%' },
        { id: 3, label: 'Storage temperature', value: '-10… +65 ºC' },
        { id: 4, label: 'Working temperature', value: '-10… +60 ºC' },
    ],

    fifthTableData: [
        { id: 1, label: 'Certifications', value: 'UL/CSA 61010-1 3rd edition, UL, VDE ' },
        { id: 2, label: 'Electrical safety, Maximum height (m)', value: '2000' },
        { id: 4, label: 'Standards', value: 'UNE EN 61010, UNE EN 61000-6-3, UNE EN 61000-6-1, IEC 664, VDE 0110, UL 94, BS EN 61000-6-2, BS EN 61000-6-4' },
    ],

    sixthTableData: [
        { id: 1, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 2, label: 'Nominal current (In)', value: '.../5A , .../1 A' },
        { id: 3, label: 'Neutral current measurement', value: '.../5A , .../1 A' },
        { id: 4, label: 'Phase current measuring range', value: '2 ... 120% In' },
        { id: 5, label: 'Maximum input current consumption', value: '0,9 VA' },
        { id: 6, label: 'Maximum pulse current', value: '100 A' },
        { id: 7, label: 'Minimum current measurement', value: '10 mA' },
    ],

    seventhTableData: [
        { id: 1, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 2, label: 'Frequency measuring range', value: '45 ... 65 Hz' },
        { id: 3, label: 'Voltage measuring range', value: '5 ...120% Un' },
        { id: 4, label: 'Nominal voltage', value: '300V Ph-N, 520V Ph-Ph' },
        { id: 5, label: 'Minimum measurement voltage (Vstart)', value: '15 V' },
    ],

    eighthTableData: [
        { id: 1, label: 'Fieldbus (BACnet)', value: 'MS/TP' },
        { id: 2, label: 'Fieldbus (ModBus)', value: 'RS-485 / RTU' },
        { id: 3, label: 'Stop bits (BACnet)', value: '1' },
        { id: 4, label: 'Stop bits (ModBus)', value: '1-2' },
        { id: 5, label: 'Parity (BACnet)', value: 'non' },
        { id: 6, label: 'Parity', value: 'without, even, odd' },
        { id: 7, label: 'Protocol', value: 'ModBus/BACnet' },
        { id: 8, label: 'Speed', value: '9600-19200' },
    ],

    ninthTableData: [
        { id: 1, label: 'LED', value: '3 LED' },
        { id: 2, label: 'Keyboard', value: '3 keys' },
        { id: 3, label: 'Display type', value: 'LCD Custom COG' },
    ],

    tenthTableData: [
        { id: 1, label: 'Input/output insulation', value: 'Optoisolated' },
        { id: 2, label: 'Quantity', value: '2' },
        { id: 3, label: 'Type', value: 'NPN Potential-free contact' },
    ],

    eleventhTableData: [
        { id: 1, label: 'Electrical life (at maximum load)', value: '60x10\u00B3 cycles' },
        { id: 2, label: 'Mechanical life', value: '10x10\u2076 cycles' },
        { id: 3, label: 'Maximum switching capacity', value: '1500 VA' },
    ],

    twelfthTableData: [
        { id: 1, label: 'Phase voltage measurement', value: '0.5% ± 1 digit' },
    ],

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVMC10() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">CVM-C10-ITF-IN-485-IC2</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">CVM-C10-ITF-IN-485-IC2, Power analyzer</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: M55942.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=M52706." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="text-lg font-medium text-gray-900">Display features and interface</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL INPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL RELAY OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.twelfthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.twelfthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
