let tableData;
const product = {
    name: 'CVM-C4',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '#' },
        { id: 2, name: 'Power Analyzers', href: '/Analyzers' },
    ],
    images: [
        {
            src: '/images/products/Analyzers/CVM-C4/CVM-C4_R.jpg',
            alt: 'CVM-C4',
        },
    ],
    description:
        'CVM-C4 is a power analyser that measures, calculates and displays the main electrical parameters for\n' +
        'single-phase and three-phase networks (with or without neutral) on its screen. Measurement is made\n' +
        'in true rms value, by means of 3 AC voltage inputs and 3 /5 A or /1 A current inputs. Relay outputs\n' +
        'enable local operation in the event of an alarm event being triggered. Its dimensions are 96 x 96 mm\n' +
        'with a depth of only 41.5 mm. Current measurement is indirectly carried out by /5A or /1A\n' +
        'transformers. It allows AC (80...270 Vac)and DC (80...270 Vdc or 18... 36 Vdc power supply systems). It\n' +
        'features RS-485 communications enabling remote data readout or Scada or Master centralisation.',

    details: [
        'For electrical parameter measurement in areas whose reduced dimensions require the installation of a space-saving panel analyser.',
        'Instantaneous value measurement of electrical parameters with communications.',
        'Logging of consumed or generated Active or Reactive Energy.',
        'The device\'s relay outputs allow the installation to be locally operated.',
        'Programmable alarms by setting relay activation time, connection delay and hysteresis.',
        '5000 imp/kWh energy impulse outputs.',
        'Energy measurement of two different sources on 2 separate meters (by digital input activation).',
        'Voltage and current THD% measurement to check for harmonics in the installation.',
    ],
    const: tableData = [
        { id: 2, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 3, label: 'Consumption', value: '6 … 18 VA' },
        { id: 4, label: 'Frequency', value: '50/60 Hz' },
        { id: 5, label: 'Nominal voltage', value: '80 ... 270 Vac' },
    ],

    secondTableData: [
        { id: 2, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 3, label: 'Consumption', value: '1.5 ... 1.8 W' },
        { id: 4, label: 'Nominal voltage', value: '80…270 Vdc' },
    ],

    thirdTableData: [
        { id: 2, label: 'Size (mm) width x height x depth', value: '96 x 96 x 41.5 (mm)' },
        { id: 3, label: 'Envelope', value: 'Polycarbonate + ABS' },
        { id: 4, label: 'Torque setting', value: '0.5 Nm' },
        { id: 5, label: 'Communications cable cross-section', value: '2.5 mm2' },
        { id: 6, label: 'Cable gauge at power supply terminals', value: '2.5 mm2' },
        { id: 7, label: 'Cable gauge at input and output terminals', value: '2.5 mm2' },
        { id: 8, label: 'Cable gauge at current terminals', value: '2.5 mm2' },
        { id: 9, label: 'Cable gauge at voltage terminals', value: '2.5 mm2' },
        { id: 10, label: 'Weight (kg)', value: '0.268' },
    ],

    fourthTableData: [
        { id: 2, label: 'Correction class', value: 'Front: IP54, Rear: IP20' },
        { id: 3, label: 'Relative humidity (without condensation)', value: '5 … 95%' },
        { id: 4, label: 'Storage temperature', value: '-20 … +70 ºC' },
        { id: 5, label: 'Working temperature', value: '-10 … +60 ºC' },
    ],

    fifthTableData: [
        { id: 2, label: 'Electrical safety, Maximum height (m)', value: '2000' },
        { id: 3, label: 'Electrical safety, Installation category', value: 'CAT III 300 V' },
        { id: 4, label: 'Electrical safety, Contamination level/class', value: 'Pollution resistance 2' },
        { id: 5, label: 'Standards', value: 'IEC 61010-1, IEC 61000-4-2, IEC 61000-4-3, IEC 61000-4-4, IEC 61000-4-5, IEC 61000-4-6, IEC 61000-4-8, IEC 61000-4-11' },
    ],

    sixthTableData: [
        { id: 2, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 3, label: 'Consumption', value: '< 0.2 VA (per phase)' },
        { id: 4, label: 'Nominal current (In)', value: '1 A / 5 A ~' },
        { id: 5, label: 'Allowable overload', value: '1.2 In continuous, 10 In instantaneous(5s)' },
    ],

    seventhTableData: [
        { id: 2, label: 'Installation category', value: 'CAT III 300 V' },
        { id: 3, label: 'Consumption', value: '< 0.2 VA (per phase)' },
        { id: 4, label: 'Sampling frequency', value: '45…65 Hz' },
        { id: 5, label: 'Input impedance', value: '> 1.7 MΩ' },
        { id: 6, label: 'Voltage measuring range', value: '45…65 Hz' },
        { id: 7, label: 'Nominal voltage', value: '100…277 V~ Ph-N (± 8%)' },
        { id: 8, label: 'Maximum permanent measurement voltage', value: '1.2 Un continuous, 2 Un instantaneous (1 min)' },
        { id: 9, label: 'Minimum measurement voltage (Vstart)', value: '10 V' },
    ],

    eighthTableData: [
        { id: 2, label: 'Data bits', value: '8' },
        { id: 3, label: 'Stop bits (ModBus)', value: '1-2' },
        { id: 4, label: 'Parity', value: 'without, even, odd' },
        { id: 5, label: 'Protocol', value: 'ModBus RTU' },
        { id: 6, label: 'Speed', value: '2400-4800-9600-19200' },
    ],

    ninthTableData: [
        { id: 2, label: 'Keyboard', value: '3 keys' },
        { id: 3, label: 'Display type', value: 'LCD' },
    ],

    tenthTableData: [
        { id: 2, label: 'Input/output insulation', value: '3,75 kV RMS' },
        { id: 3, label: 'Quantity', value: '2' },
        { id: 4, label: 'Type', value: 'Potential-free contact' },
        { id: 5, label: 'Maximum short-circuit current', value: '4 mA' },
        { id: 6, label: 'Maximum open circuit voltage', value: '30 V' },
    ],

    eleventhTableData: [
        { id: 2, label: 'Quantity', value: '2' },
        { id: 3, label: 'Type', value: 'Relay' },
        { id: 4, label: 'Resistive load (max.)', value: '250 Vca / 5 Aca, 30 Vcc / 5 Acc' },
        { id: 5, label: 'Maximum current', value: '5 A ~' },
        { id: 6, label: 'Maximum open contact voltage', value: '277 V ~ / 30 Vdc' },
        { id: 7, label: 'Electrical life', value: '(250 V ~ / 5 A) 1 x 105' },
        { id: 8, label: 'Maximum switching capacity', value: '1385 VA / 150 W' },
    ],

    twelfthTableData: [
        { id: 2, label: 'Frequency measurement', value: '0.5 %' },
        { id: 3, label: 'Phase current measurement', value: '0.2 %' },
        { id: 4, label: 'Reactive energy measurement (kvarh)', value: '0.5 %' },
        { id: 5, label: 'Reactive power measurement (kvar)', value: '0.5 %' },
        { id: 6, label: 'Active energy measurement (kWh)', value: '0.5 %' },
        { id: 7, label: 'Active power measurement (kW)', value: '0.5 %' },
        { id: 8, label: 'Phase voltage measurement', value: '0.2 %' },
    ],
    thirteenthTableData: [
        { id: 2, label: 'Pulse width', value: 'Minimum pulse width: 80mA' },
        { id: 3, label: 'Type', value: 'Passive impulse' },
        { id: 4, label: 'Maximum frequency', value: '10 Hz' },
        { id: 5, label: 'Maximum current', value: '27 mA' },
        { id: 6, label: 'Maximum voltage', value: '27 Vcc' },
    ],

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVMC4() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">CVM-C4-ITF-485-ICT2</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">CVM-C4-ITF-485-ICT2, Power analizer, panel mounted 96x96</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: M52706.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=M52706." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Data Sheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>
                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL INPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL RELAY OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.twelfthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.twelfthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL TRANSISTOR OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirteenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirteenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
