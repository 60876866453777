let tableData;
const product = {
    name: 'CEM-C31-T1',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Metering', href: '/Metering' },
    ],
    images: [
        {
            src: '/images/products/Metering/CEMC31/CEMC31_R1.jpg',
            alt: 'CEM   ',
        },
    ],
    description:
        'Three-phase electrical energy meter with indirect measurement, 5(10)A (, CEM-C31), direct measurement 65 A (CEM-C21) or single-phase energy meter (CEM-C10).' +
        'Built-in LCD display (7 digits) with rotating screen system. Features built-in RS-485 communications. Also features\n' +
        '2 buttons (1 sealable button) for viewing all the measured information.',
    highlights: [
        "MID certification, module B+D (depending on the type)",
        "Class 1 active energy (Class B, in accordance with MID), Class 2 reactive energy",
        "Complies with the EN 50470 (MID European standards) or IEC 62052-11 standards (international standards), depending on the type.",
        "Compact size (CEM-C10: 2 modules, 36 mm, CEM-C21 y CEM-C31: 4 modules, 72 mm)",
        "Resettable partial meter",
        "1 programmable impulse output, in accordance with DIN 43864 (CEM-C10, CEM-C31-T1, CEM-C21-T1 models)",
        "1 Digital input for Tariff selection and impulse count (CEM-C31-D, CEM-C21-DS)",
        "Indicates bad connections on the screen",
        "Energy storage, even in the case of bad connections"
    ],
    details: [
        "Redundant meter for verifying the energy allocated by the energy provider.",
        "Energy consumption report sent to a remote system (PLC/BMS).",
        "Cost control for achieving a high consumption/unit ratio in industrial processes.",
        "Display of electrical parameters (V, A, kW, kW·h, PF, etc.), per phase and three-phase."
    ],
    const: tableData = [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "< 2 W, 10 VA" },
        { label: "Frequency", value: "50 … 60 Hz" },
        { label: "Nominal voltage", value: "230 V / 400 V ~ (± 20 %)" },
    ],

    secondTableData: [
        { label: "Size (mm) width x height x depth", value: "70 x 90 x 64 (mm)" },
        { label: "Weight (kg)", value: "0,37" },
    ],

    thirdTableData: [
        { label: "Relative humidity (without condensation)", value: "5 … 95 %" },
    ],

    fourthTableData: [
        { label: "Consumption", value: "0.3 VA … 10 A" },
        { label: "Reference current (Iref)", value: "5 A" },
        { label: "Maximum current", value: "10 A" },
        { label: "Minimum current measurement", value: "0.050 A" },
        { label: "Transition current", value: "0.25 A" },
    ],

    fifthTableData: [
        { label: "Consumption", value: "< 2W , < 10VA (In, Vref)" },
        { label: "Nominal frequency", value: "50 / 60 Hz" },
        { label: "Nominal voltage", value: "3 x 127/220 ... 3 x 230/400 V ~" },
    ],

    sixthTableData: [
        { label: "Insulation voltage, circuit", value: "4 kV RMS 50 Hz during 1 min" },
    ],

    seventhTableData: [
        { label: "Data bits", value: "8" },
        { label: "Stop bits (ModBus)", value: "1" },
        { label: "Parity", value: "non" },
        { label: "Protocol", value: "ModBus" },
        { label: "Speed", value: "9600" },
    ],

    eighthTableData: [
        { label: "Electrical safety, Maximum height (m)", value: "2000" },
        { label: "Standards", value: "EN 50470-1, EN 50470-3, IEC 62053-21, IEC 62053-23, EN 62056-21" },

    ],

    ninthTableData: [
        { label: "LED", value: "2 LED: kWh, 20000 imp/kW, kvarh, 20000 imp/kvarh" },
        { label: "Keyboard", value: "2 Keys" },
        { label: "Display type", value: "LCD" },
        { label: "Maximum value", value: "999999.9 kWh" },
    ],

    tenthTableData: [
        { label: "Quantity", value: "1" },
        { label: "Pulse output, time period (Ton / Toff)", value: "Ton: 200 ms" },
        { label: "Maximum current", value: "50 mA" },
        { label: "Maximum voltage", value: "24 Vcc" },
    ],

    eleventhTableData: [
        { label: "Reactive energy measurement (kvarh)", value: "Class 2.0 (IEC 62053-23)" },
        { label: "Active energy measurement (kWh)", value: "Class 1 (IEC 62053-21)" },
    ],

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVMC10() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">CEM-C31-T1</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">CEM-C31-T1, Indirect three-phase energy meter</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: Q23511.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=Q23511." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="text-lg font-medium text-gray-900">Display features and interface</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASUREMENT CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASUREMENT CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ELECTRICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL TRANSISTOR OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
