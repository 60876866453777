import React, { Component } from "react";
import Slider from "react-slick";
import "./HomeSlidestyle.css"; // Import the custom CSS file

export default class HomeSlides extends Component {
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress);
        document.addEventListener("touchmove", this.handleTouchMove, { passive: false });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
        document.removeEventListener("touchmove", this.handleTouchMove);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 37) { // Left arrow key
            this.slider.slickPrev();
        } else if (event.keyCode === 39) { // Right arrow key
            this.slider.slickNext();
        }
    };

    handleTouchMove = (event) => {
        event.preventDefault();
    };

    render() {
        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: "linear",
            width: '100%',
            swipeToSlide: true // Enable swiping on touchscreen
        };

        return (
            <div>
                <Slider {...settings} ref={(slider) => (this.slider = slider)}>
                    <HomeSlideContainer
                        imageUrl={"/images/slideshow/slide5.jpg"}
                        title={"Circutor"}
                        heading={"Electrical Energy Efficiency"}
                        text={"As a sole distributor of Circutor, we're dedicated to pioneering innovative approaches to optimize electrical systems, leading the charge towards a more sustainable future."}
                    />
                    <HomeSlideContainer
                        imageUrl={"/images/company/tsudakoma.jpg"}
                        title={"Industrial Trading & Engineering Corporation"}
                        heading={"Textile Machinery"}
                        text={"Partnering with Tsudakoma and providing advanced weaving solutions, we ensure the highest standards of precision and efficiency in fabric manufacturing."}
                    />
                    <HomeSlideContainer
                        imageUrl={"/images/company/dairy.jpg"}
                        title={"Farm360"}
                        heading={"Dairy Farming"}
                        text={"Committed to sustainability and quality, our dairy division prioritizes ethical farming practices to deliver premium dairy products."}
                    />
                    <HomeSlideContainer
                        imageUrl={"/images/slideshow/slide4.jpg"}
                        title={"Organics360"}
                        heading={"Vermicompost Fertilizer"}
                        text={"Our vermicompost fertilizer initiative transforms dairy farm waste into nutrient-rich compost, promoting eco-friendly agricultural practices."}
                    />
                </Slider>
            </div>
        );
    }
} 

const HomeSlideContainer = ({ imageUrl, title, heading, text }) => (
    <div className="home-slide-container">
        <img src={imageUrl} className="home-slide-image" style={{ width: "100%" }}/>
        {heading && text && title &&(
            <div className="home-slide-overlay">
                <div className="home-overlay-title">
                    {title}
                </div>
                <div className="home-overlay-heading">
                    {heading}
                </div>
                <div className="home-overlay-text">
                    {text}
                </div>
            </div>
        )}
    </div>
);
