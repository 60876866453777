import '../../../../styles/tailwind.css';

const products = [
    {
        id: 1,
        name: 'CVM-C10',
        href: '/CVMC10',
        description: 'Power analyzer',
        imageSrc: 'images/products/Analyzers/CVM-C10/CVM-C10_R1.jpg',
        imageAlt: 'CVM-C10',
    },
    {
        id: 2,
        name: 'CVM-C4',
        href: '/CVMC4',
        description: 'Power analyzer, panel mounted 96x96',
        imageSrc: '/images/products/Analyzers/CVM-C4/CVM-C4_R1.jpg',
        imageAlt: 'CVM-C4',
    },
    {
        id: 3,
        name: 'CVM-A1500',
        href: '/CVMA1500',
        description: 'Power quality analyzers with recording of quality events and transients',
        imageSrc: '/images/products/Analyzers/CVM-A1500/CVM-A1500_R1.jpg',
        imageAlt: 'CVM-A1500',
    },
    {
        id: 4,
        name: 'CVM-E3-Mini',
        href: '/CVME3',
        description: 'Power analyzer',
        imageSrc: '/images/products/Analyzers/CVM-E3-Mini/CVM-E3-MINI_R.jpg',
        imageAlt: 'CVM-E3-Mini',
    },
    {
        id: 5,
        name: 'CVM-B100',
        href: '/CVMB100',
        description: 'Power analyzer',
        imageSrc: '/images/products/Analyzers/CVM-B100/CVMB-100_R1.jpg',
        imageAlt: 'CVM-B100',
    },
    {
        id: 6,
        name: 'Line-EDS',
        href: '/LineEDS',
        description: 'Data collection systems. Integrates WEBSITE.',
        imageSrc: '/images/products/Analyzers/Line-EDS/Line-EDS_R1.jpg',
        imageAlt: 'Line-EDS',
    },
    {
        id: 6,
        name: 'MYeBOX-1500-4G',
        href: '/myebox',
        description: 'Portable power analyzer.',
        imageSrc: '/images/products/Analyzers/myebox/myebox_1.png',
        imageAlt: 'myebox',
    },

]

export default function Analyzers() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{textAlign: "center"}}>Power Analyzers</h1>
                <div className="my-6 text-center text-gray-600">
                    Discover advanced electrical power analyzers designed to monitor and optimize energy usage in real-time. These devices provide precise measurements, helping businesses reduce costs, improve efficiency, and ensure system reliability.
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 py-20">
                    {products.map((product) => (
                        <a key={product.id} href={product.href} className="group">
                            <div className="aspect-w-7 aspect-h-5 bg-gray-200 rounded-lg overflow-hidden">
                                <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="object-cover w-full h-full group-hover:opacity-75"
                                />
                            </div>
                            <h3 className="mt-4 text-lg text-gray-900">{product.name}</h3>
                            <p className="mt-1 text-sm font-medium text-gray-700">{product.description}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
