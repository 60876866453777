import React, { Component } from "react";
import Slider from "react-slick";
import "./CircutorSlidestyle.css"; // Import the custom CSS file

export default class CircutorSlides extends Component {
    render() {
        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: "linear",
            width: '100%',
        };

        return (
            <div>
                <Slider {...settings}>
                    <SlideContainer
                        imageUrl={"/images/slideshow/slide5.jpg"}
                        heading={"Measurement and Control"}
                        text={"Digital instrumentation devices rapidly monitor and display variables in industrial settings, enabling real-time malfunction detection and potential integration into SCADA systems."}
                    />
                    <SlideContainer
                        imageUrl={"/images/slideshow/slide3.jpg"}
                        heading={"Measurement and Control"}
                        text={"Digital instrumentation devices rapidly monitor and display variables in industrial settings, enabling real-time malfunction detection and potential integration into SCADA systems."}
                    />
                    <SlideContainer
                        imageUrl={"/images/slideshow/slide4.jpg"}
                    />
                    <SlideContainer
                        imageUrl={"/images/slideshow/slide1.jpg"}
                        heading={"The My E-BOX"}
                        text={"Portable power analyzers are essential for energy audits, reviewing power quality, and monitoring electrical parameters to detect supply and consumption issues in electrical installations."}
                    />
                </Slider>
            </div>
        );
    }
}

const SlideContainer = ({ imageUrl, heading, text }) => (
    <div className="slide-container">
        <img src={imageUrl} className="slide-image" />
        {heading && text && (
            <div className="slide-overlay">
                <div className="overlay-heading">
                    {heading}
                </div>
                <div className="overlay-text">
                    {text}
                </div>
            </div>
        )}
    </div>
);
