import React, { Component } from 'react';
import CircutorSlides from "./CircutorSlides";
import CircutorProductList from "./CircutorProductList";

class CircutorComponent extends Component {

    render() {
        return (
            <div>
                <CircutorSlides />
                <CircutorProductList />
            </div>
        );
    }
}

export default CircutorComponent;