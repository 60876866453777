let tableData;
const product = {
    name: '410-QD1A-90B10',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Metering', href: '/metering' },
    ],
    images: [
        {
            src: '/images/products/Metering/CIRWATT-B410D/CIRWATT-B410D_R.jpg',
            alt: 'CIRWATT',
        },
    ],
    description:
        'CIRWATT-B410D is a standard three phase direct connection meter, ideal for industrial three-phase applications. With class B in active energy according to' +
        ' European Directive MID (EN 50470) or class 1 according to IEC-62053-21, it has multiple communication options and expansion modules that allow it to adapt to any type of installation.',
    const: tableData = [
        { label: "Tolerance", value: "80 % ... 115 % Un" },
        { label: "Consumption", value: "< 2 W; < 10 VA" },
        { label: "Frequency", value: "50 / 60 Hz" },
        { label: "Nominal voltage", value: "3 x 230 (400) V - 3 x 127 (230) V" },
    ],

    secondTableData: [
        { label: "Performance-guarantee", value: "> 20 years @ 30 ºC" },
        { label: "Type", value: "Lithium" },
    ],

    thirdTableData: [
        { label: "Size (mm) width x height x depth", value: "172 x 255 x 67 (mm)" },
        { label: "Envelope", value: "DIN 43859" },
        { label: "Weight (kg)", value: "2,8" },
    ],

    fourthTableData: [
        { label: "Relative humidity (without condensation)", value: "95 % max." },
        { label: "Storage temperature", value: "-40 ... +85 ºC" },
        { label: "Working temperature", value: "-40 ... +70 ºC" },
    ],

    fifthTableData: [
        { label: "Connection", value: "Asymmetrical" },
        { label: "Consumption", value: "< 2 W; 10 VA" },
        { label: "Nominal frequency", value: "50 / 60 Hz" },
        { label: "Nominal voltage", value: "3 x 230/400 V (Request for other configurations)" },
    ],

    sixthTableData: [
        { label: "Consumption", value: "< 0,1 V·A" },
        { label: "Reference current (Iref)", value: "10 A" },
        { label: "Maximum current", value: "100 A" },
        { label: "Minimum current measurement", value: "< 0,5 x Itr" },
    ],

    seventhTableData: [
        { label: "Protocol", value: "REE, basado en IEC 870-5-102" },
        { label: "Type", value: "RS-232 / RS-485" },
    ],

    eighthTableData: [
        { label: "Hardware", value: "IEC 62056-21" },
        { label: "Protocol", value: "REE, based on IEC 870-5-109" },
        { label: "Type", value: "Serial;bi-directional" },
    ],

    ninthTableData: [
        { label: "Resolution of the display", value: "up to 8 digits (8 mm)" },
        { label: "Display type", value: "LCD" },
    ],

    tenthTableData: [
        { label: "Memory capacity", value: "Data: non-volatile memory, Setup and events: serial-flash" },
        { label: "Write time", value: "4000" },
        { label: "Type", value: "Serial flash" },
    ],

    eleventhTableData: [
        {
            label: "Standards",
            value:
                "UNE-EN 50470-1 Electricity metering equipment (a.c.) -- Part 1: General requirements, tests and test conditions - Metering equipment -class indexes B-)\nUNE-EN 50470-3 Electricity metering equipment (a.c.) -- Part 3: Particular requirements - Static meters for active energy -class indexes B-\nIEC 62052-11, IEC 62053-21, IEC 62053-22 (Standards for static active energy meters for alternating current of class 0.2s, 0.5s)\nUNE-EN 55022 (Conducted Emissions: Class B, Radiated Emissions: Class B)\nUNE-EN 61000-4-2, UNE-EN 61000-4-3, UNE-EN 61000-4-4, UNE-EN 61000-4-5, UNE-EN 61000-4-6, UNE-EN 61000-4-8, UNE-EN 61000-4-11",
        },
    ],

    twelfthTableData: [
        { label: "Hardware", value: "CENELEC A or CENELEC B" },
        { label: "Protocol", value: "CirPLC & PEP (PLC Encapsulated Protocol)" },
        { label: "Modulation system", value: "DSCK with repeater system" },
    ],

    thirteenthTableData: [
        { label: "Reactive energy measurement (kvarh)", value: "IEC 62053-23 (Class 2)" },
        { label: "Active energy measurement (kWh)", value: "EN 50470 (Class B) IEC 62053-21 (Class 1)" },
    ],

    fourteenthTableData: [
        { label: "Billing closures", value: "12 locks per contract. Programable date and hour" },
        { label: "Load curve", value: "2 load curves, programmable integration time (1 … 253 min)" },
        {
            label: "Optional",
            value:
                "Communications: RS-232 / PLC, RS-485 / PLC, RS-232 / RS-232, RS-485 / RS-485, RS-232 / RS-485, RS-232 / Ethernet, R-485 / Ethernet. Expansion boards: No inputs / outputs, 4 relay outputs (Rate Indicator), 2 relay inputs / 4 pulse outputs, 4 pulse inputs, Differential current measurement, 2 relay outputs / 2 pulse outputs, / 2 pulse inputs",
        },
        { label: "Tariff programming", value: "12 days 10 types of data 9 types of tariffs 30 public holidays 12 special days" },
    ],

    fifteenthTableData: [
        { label: "Source", value: "Temperature compensated oscillator" },
        { label: "Accuracy (EN 61038)", value: "< 0,5 s/day (23 ºC)" },
        { label: "Type", value: "Gregorian calendar" },
    ]

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVMC10() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">410-QD1A-90B10</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">410-QD1A-90B10, Three-phase energy meters direct connection</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: QB4B0.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=QP102" // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">BATTERY SPECIFICATION</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASUREMENT CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">OPTICAL COMMUNICATION INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEMORY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">PLC</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.twelfthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.twelfthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirteenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirteenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">FEATURES / PERFORMANCE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourteenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourteenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CLOCK</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifteenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifteenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
