let tableData;
const product = {
    name: 'SVGm-4WF-069M-400',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Harmonic Filtering & Power Factor Correction', href: '/Correction' },
    ],
    images: [
        {
            src: '/images/products/Correction/SVGM4W69M400/SVGM4W69M400.jpg',
            alt: 'SVGM4W69M400',
        },
    ],
    description:
        'SVGm cabinet-type static var generators are the most accurate power factor correction solution in both unbalanced three-phase systems and installations with inductive and capacitive reactive power. They\n' +
        'can be used in three-phase industrial, commercial or service installations, and they are not affected by the installation\'s harmonics. Much safer device with minimal maintenance. This product range features rack assembly in standard cabinets, designed to simplify installation.',
    highlights: [
        'Power factor correction (inductive/capacitive) from 30 to 100 kvar (3W) and 20 to 69 kvar (4W) per cabinet.',
        'The 100 kvar (3W) and 69 kvar (4W) rack-type module\'s compact dimensions make it easy to install in standard cabinets.',
        'Multi-range voltage and frequency (50/60 Hz).',
        'Harmonic current immunity.',
        'Cos φ range from 0.7 inductive...to...0.7 capacitive.',
        'Web-based performance monitoring.',
        'Internal short-circuit protection.',

    ],

    const: tableData = [
    { label: "Nominal voltage", value: "208 … 400 V~ F-F (± 10 %)" },
    { label: "Frequency", value: "50 / 60 Hz ± 5 %" },
    { label: "Consumption", value: "2070 W (Consumo máximo)" },
],

    secondTableData : [
    { label: "Maximum phase current", value: "100 A (RMS)" },
    { label: "Earthing system", value: "TN, TT" },
],

    thirdTableData : [
    { label: "Consumption", value: "1,5 VA por transformador" },
    { label: "Transformation ratio", value: "Transformer: 5/5A … 5000/5A" },
],

    fourthTableData : [
    { label: "Technology / Type", value: "RS-485" },
    { label: "Protocol", value: "Modbus/RTU" },
],

    fifthTableData : [
    { label: "Technology / Type", value: "Ethernet" },
    { label: "Protocol", value: "TCP/IP, Modbus TCP" },
],

    sixthTableData : [
    { label: "Working temperature", value: "-10 … +45 ºC" },
    { label: "Storage temperature", value: "-20 … +50 ºC" },
    { label: "Relative humidity (without condensation)", value: "0 …. 95 %" },
    { label: "Protection class", value: "IP20" },
],

    seventhTableData : [
    { label: "Noise", value: "< 60 dBA" },
    { label: "Weight (kg)", value: "56" },
    { label: "Size (mm) width x height x depth", value: "439 x 745 x 288 (mm)" },
],

    eighthTableData : [
    { label: "Filtering / Response time", value: "< 100 µs" },
    { label: "Reactive power compensation (Kvar)", value: "Selectable: 0.7 inductive ... 1 ... 0.7 capacitive" },
    { label: "Parallel assembly/installation", value: "Up to 100 devices of different caliber. Connection of transformers only in Master unit." },
],

    ninthTableData : [
    { label: "Standards", value: "IEC 61000-6-4, UNE-EN 55011, IEC 61000-6-2, IEC 62477-1, IEC 61439-1, IEC 60721-3-3 (Indoor conditioned)" },
    { label: "Electrical safety, Maximum height (m)", value: "3000 (2000 m sin limitar prestaciones)" },
]
}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function COMPC12() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">SVGm-4WF-069M-400</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">SVGm-4WF-069M-400, Static Var Generator with multilevel technology</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: R4P4MC.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=R4P4MC." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ELECTRICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASUREMENT CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">SERIAL COMMUNICATION</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATION NETWORK</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">FEATURES / PERFORMANCE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
