let tableData;
const product = {
    name: 'CVM-B100',
    price: '$192',
    href: '#',
    breadcrumbs: [
        {id: 1, name: 'Home', href: '#'},
        {id: 2, name: 'Power Analyzers', href: '/Analyzers'},
    ],
    images: [
        {
            src: '/images/products/Analyzers/CVM-B100/CVMB-100_R.jpg',
            alt: 'CVM-B100',
        },
    ],
    description:
        'The CVM-B100 and CVM-B150 units are panel mounted three-phase power analyzers (dimensions: 96x96 and 144x144 mm, respectively).\n' +
        'Both offer 4-quadrant measurement (consumption and generation). Suitable for Medium or Low voltage installations, in both 3 or 4-wire\n' +
        'three-phase circuits, two-phase circuits with or without neutral, single-phase circuits or ARON connections.\n' +
        'The CVM-B100 and CVM-B150 high-performance units feature a measurement engine that allows the user to analyse many different\n' +
        'electrical parameters, in addition to offering a large variety of optional expansion modules for the same unit.',
    highlights: [
        "Format: 96x96 (CVM B100) and 144x144 (CVM B150)",
        "High-resolution VGA colour screen",
        "IP 65 front panel correction (with sealing joint)",
        "5 voltage inputs (3 phases + neutral + earth) 1000 Vf-f",
        "4 Current inputs, ITF",
        "Class 0.2 voltage and current accuracy",
        "Class 0.5S energy accuracy",
        "Expandable unit, up to 4 modules, combining digital and analogue outputs, Modbus/TCP, MBus, LonWorks, Profibus, XML/Web",
        "Modular (optional addition of expansion modules)",
        "Touch-sensitive movement buttons",
        "Universal power supply source",
        "RS485 communications port (Modbus/RTU and BACnet protocols)",
        "Customisation of parameters to be displayed",
        "Operating hour indicator for preventive maintenance."
    ],
    details: [
        'Control and monitoring of all electrical parameters measured in any electric distribution panel and low and high-voltage connection points.',
        '4 alarms (2 per transistor and 2 per relay), fully and independently programmable: low or high value, hysteresis, connection/disconnection delays, normally open or closed standby status and interlocking.',
        'Generation of impulses with transistor outputs, fully and independently configurable over any incremental parameter (energy, costs, kgCO2, total meter or tariff hours).',
        'Transducer that converts analogue signals to any instantaneous parameter that the unit can measure or calculate, with built-in expansion modules with analogue outputs.',
        'Display of process signals, with a built-in expansion module with analogue inputs; optional reporting of these signals to SCADA systems through communication systems.',
        'Control of electrical load or alarm signal operations by programming the transistor or relay outputs that are built-in or added through expansion modules.',
        'Autonomous datalogger with web server, connected to a M-CVMAB-Datalogger module. Enables direct monitoring of the historical data stored in the unit via a conventional web browser.'
    ],
    const: tableData = [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "max. 23.9 VA" },
        { label: "Frequency", value: "45 ... 65Hz" },
        { label: "Nominal voltage", value: "100 ... 240 V ~" },
    ],

    secondTableData: [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "max. 12.2 W" },
        { label: "Nominal voltage", value: "120 … 300 Vdc" },
    ],

    thirdTableData: [
        { label: "Size (mm) width x height x depth", value: "97 x 99 x 99 (mm)" },
        { label: "Envelope", value: "Self-extinguishing V0 plastic" },
        { label: "Fastening", value: "Panel 92x92" },
        { label: "Weight (kg)", value: "0,516" },
    ],

    fourthTableData: [
        { label: "Correction class", value: "IP 40 (Front), IP 65 (Sealing), IP 30 (unmounted)" },
        { label: "Relative humidity (without condensation)", value: "5 ... 95%" },
        { label: "Storage temperature", value: "-20… +80 ºC" },
        { label: "Working temperature", value: "-10…+60 ºC" },
    ],

    fifthTableData: [
        { label: "Certifications", value: "UL 61010-1 3rd edition, CAN/CSA-C22.2 No.61010-1 3rd. edition 2012-05" },
        { label: "Electrical safety, Maximum height (m)", value: "2000" },
        { label: "Standards", value: "IEC 61010-1 (1rd. Edition), UNE-EN 61000-6-2, UNE-EN 61000-6-4, IEC 60664-1, IEC 61010-2-030 (First Edition). Measurement according to IEC 61557-12" },
    ],

    sixthTableData: [
        { label: "Installation category", value: "CAT III 600 V" },
        { label: "Nominal current (In)", value: "…/5A, …/1A, …/0.250A" },
        { label: "Phase current measuring range", value: "0.01…10A (…/5A),0.01…2A (…/1A), 0.01…0.5A (…/0.250A)" },
        { label: "Neutral current measuring range", value: "0.02...0.5A (.../0.250A, calculated)" },
        { label: "Maximum input current consumption", value: "0,9 VA" },
        { label: "Maximum pulse current", value: "100 A" },
        { label: "Minimum current measurement", value: "0,01 A (…/5A, …/1A, …/0,250A" },
    ],

    seventhTableData: [
        { label: "Installation category", value: "CAT III 600V" },
        { label: "Input impedance", value: "1.2MΩ" },
        { label: "Frequency measuring range", value: "40…70Hz" },
        { label: "Voltage measuring range", value: "40…70 Hz" },
        { label: "Maximum input voltage consumption", value: "0,15VA" },
        { label: "Minimum measurement voltage (Vstart)", value: "10 V~" },
    ],

    eighthTableData: [
        { label: "Fieldbus (BACnet)", value: "MS/TP" },
        { label: "Fieldbus (ModBus)", value: "RS-485 / RTU" },
        { label: "Stop bits (BACnet)", value: "1" },
        { label: "Stop bits (ModBus)", value: "1-2" },
        { label: "Parity (BACnet)", value: "non" },
        { label: "Parity", value: "non-pair-impar (Modbus)" },
        { label: "Protocol", value: "ModBus/BACnet" },
        { label: "Speed", value: "9600-19200-38400-57600-76800-115200" },
    ],

    ninthTableData: [
        { label: "Display format", value: "4:3" },
        { label: "LED", value: "3 LED (CPU-Keys-ALARM)" },
        { label: "Resolution of the display", value: "VGA (640x480)" },
        { label: "Keyboard", value: "Capacitive, 3 keys" },
        { label: "Display type", value: "TFT color" },
        { label: "Visible display area size", value: "3.5\"" },
    ],

    tenthTableData: [
        { label: "Input/output insulation", value: "4 kV" },
        { label: "Quantity", value: "2" },
        { label: "Type", value: "Potential-free contact" },
        { label: "Maximum short-circuit current", value: "5 mA" },
        { label: "Maximum open circuit voltage", value: "15 Vdc" },
    ],

    eleventhTableData: [
        { label: "Electrical life (at maximum load)", value: "3x104 cycles" },
        { label: "Mechanical life", value: "1x107 cycles" },
        { label: "Maximum switching capacity", value: "1500 VA" },
    ],

    twelfthTableData: [
        { label: "Pulse width", value: "1 ms" },
        { label: "Quantity", value: "2" },
        { label: "Type", value: "NPN" },
        { label: "Pulse output, time period (Ton / Toff)", value: "0.3 ms/0.7 ms" },
        { label: "Maximum frequency", value: "1 kHz" },
        { label: "Maximum current", value: "130 mA" },
        { label: "Maximum voltage", value: "48 Vdc" },
    ],
    thirteenthTableData: [
        { label: "Frequency measurement", value: "Class 0.1 (…/5A, …/1A, …/0.250A)" },
        { label: "Phase current measurement", value: "class 1 ±1 digit 0.05…6A (…/5A),0.01…1.2A ( …/1A), calculated (…/0.250A)" },
        { label: "Neutral current measurement", value: "class 1 ±1 digit 0.05…6A (…/5A),0.01…1.2A ( …/1A), calculated (…/0.250A)" },
        { label: "Reactive energy measurement (kvarh)", value: "(IEC 62053-23) Class 1 (…/5A), Class 2 (…/1A, …/0.250A)" },
        { label: "Reactive power measurement (kvar)", value: "(Vn 230/110 Vac) Class 1 ±1 digit 0.05…6A (…/5A),0.01…1.2A ( …/1A), 0.01…0.3A (…/0.250A)" },
        { label: "Apparent power measurement (kVA)", value: "(Vn 230/110 Vac) class 0.5 ±1 digit 0.05…6A (…/5A),0.01…1.2A ( …/1A), 0.01…0.3A (…/0.250A)" },
        { label: "Active energy measurement (kWh)", value: "(IEC 62053-22) Class 0.5S (…/5A), Class 1 (…/1A), Class 1 (…/0.250A)" },
        { label: "Active power measurement (kW)", value: "(Vn 230/110 Vac) class 0.5 ±1 digit 0.05…6A (…/5A),0.01…1.2A ( …/1A), 0.01…0.3A (…/0.250A)" },
        { label: "Power factor measurement", value: "class 0.5 (…/5A, …/1A, …/0.250A)" },
        { label: "Current THD", value: "class 1 (…/5A, …/1A, …/0.250A)" },
        { label: "Voltage THD", value: "class 1 (…/5A, …/1A, …/0.250A)" },
        { label: "Phase voltage measurement", value: "Class 0.5 ±1 digit (50…600 Vca, …/5A, …/1A, …/0.250A)" },
        { label: "Neutral voltage measurement", value: "Class 0.5 ±1 digit (50…600 Vca, …/5A, …/1A, …/0.250A)" },
        { label: "Current harmonics (THD)", value: "class 1 (…/5A, …/1A, …/0.250A)" },
        { label: "Voltage harmonics (THD)", value: "class 1 (…/5A, …/1A, …/0.250A)" },

    ],

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVMB100() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">CVM-B100-ITF-485-ICT2</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">CVM-B100-ITF-485-ICT2, Power analyzer</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: M56011.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=M56011." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>
                        <div className="mt-10">
                            <h3 className="text-lg font-medium text-gray-900">Features and Interface</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL INPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL RELAY OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL TRANSISTOR OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.twelfthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.twelfthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirteenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirteenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
