let tableData;
const product = {
    name: 'Line-EDS',
    price: '$192',
    href: '#',
    breadcrumbs: [
        {id: 1, name: 'Home', href: '/'},
        {id: 2, name: 'Power Analyzers', href: '/Analyzers'},
    ],
    images: [
        {
            src: '/images/products/Analyzers/Line-EDS/Line-EDS_R.jpg',
            alt: 'CVM-B100',
        },
    ],
    description:
        'The Line-EDS-PS is a gateway with PowerStudio embedded. This module, by itself, lets you set up a supervisory and telemanagement (SCADA) system. By using the expansion modules of the line range or any Modbus (TCP or RTU) device on the market, it is able to integrate any process signal that is to be measured.\n' +
        'By programming the device with PowerStudio, you can incorporate any actuating logic for analogue or digital outputs, allowing you to create an automated management system that performs actions based on the input signals.\n' +
        'The device can be connected via cabled (Ethernet) or wireless (Wi-Fi) networks. The data displays, screens and reports can be accessed via the PowerStudio client or via a web browser thanks to the integrated web server\n',
    details: [
        'Electricity consumption monitoring system with active alarm management by e-mail (cos φ, maximum power, harmonics, etc.)',
        'Sectorization of consumption, load management, invoice simulation, allocation of production costs, etc.',
        'Efficient management of systems through hourly schedules (HVAC, lighting, etc.)',
        'Efficient management of HVAC systems by regulating the supply setpoints.',
        'Control of pumping systems.',
        'Monitoring of industrial processes.',
        'Management of multipoint consumption (electricity, water, gas, etc.)',
        'Analysis of equipment performance (compressed air, HVAC, etc.)'
        ],
    const: tableData = [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "11 … 28 VA" },
        { label: "Frequency", value: "50 … 60 Hz" },
        { label: "Nominal voltage", value: "120 … 264 V ~" },
    ],

    secondTableData: [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "2.5 … 7 W" },
        { label: "Nominal voltage", value: "190 … 300 Vdc" },
    ],

    thirdTableData: [
        { label: "Size (mm) width x height x depth", value: "52.5 x 118 x 70 (mm)" },
        { label: "Screw type", value: "Flat, M2.5" },
        { label: "Envelope", value: "Self-extinguishing V0 plastic" },
        { label: "Torque setting", value: "≤ 0.4 Nm" },
        { label: "Cable gauge at power supply terminals", value: "2.5 mm2" },
        { label: "Fastening", value: "DIN rail" },
        { label: "Weight (kg)", value: "0,187" },
    ],

    fourthTableData: [
        { label: "Correction class", value: "IP30, Front: IP40" },
        { label: "Relative humidity (without condensation)", value: "5 … 95%" },
        { label: "Storage temperature", value: "-20 … +80 ºC" },
        { label: "Working temperature", value: "-10 … +50 ºC" },
    ],

    fifthTableData: [
        { label: "Certifications", value: "UL 61010-1" },
        { label: "Electrical safety, Maximum height (m)", value: "2000" },
        { label: "Standards", value: "UNE-EN 61010-1, UNE-EN 61000-6-2, UNE-EN 61000-6-4, UL 61010-1" },
    ],

    sixthTableData: [
        { label: "Effective radiated power (ERP)", value: "11,25 dBm" },
        { label: "IP address for local communications", value: "100.0.0.1" },
        { label: "Connection mode to Network", value: "DHCP ON/OFF (ON by default)" },
        { label: "Effective isotropic radiated power (EIRP)", value: "13,4 dBm" },
        { label: "Band", value: "2,4 GHZ" },
        { label: "Fieldbus", value: "RS-485" },
        { label: "Data bits", value: "8" },
        { label: "Stop bits (ModBus)", value: "1" },
        { label: "Connector", value: "RJ-45" },
        { label: "Parity", value: "non (Modbus)" },
        { label: "Protocol", value: "Modbus RTU / Web server - XML" },
        { label: "Speed", value: "Ethernet 10 /100 BT (Mbit/s), Modbus: 9600-19200-38400-57600-115200" },
        { label: "Standards", value: "IEEE 802.11 b / g / n" },
        { label: "Maximum output power", value: "8,9 dBm" },
        { label: "Connection mechanism", value: "Ethernet 10BaseT / 100BaseTX autodetectable Wi-Fi" },
    ],

    seventhTableData: [
        { label: "LED", value: "5 LED" },
    ],

    eighthTableData: [
        { label: "Pulse width", value: "1 ms" },
        { label: "Quantity", value: "2" },
        { label: "Type", value: "Optocoupler (Open-collector)" },
        { label: "Maximum frequency", value: "500 Hz" },
        { label: "Maximum current", value: "120 mA" },
        { label: "Maximum voltage", value: "48Vcc" },
    ],

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LineEDS() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Line-EDS-PSS</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">Line-EDS-PSS, Data collection systems. Integrates WEBSITE.</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: M61085.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=m61085" // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>
                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL TRANSISTOR OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
