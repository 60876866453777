let tableData;

const product = {
    name: 'CVM-E3-Mini',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '#' },
        { id: 2, name: 'Power Analyzers', href: '/Analyzers' },
    ],
    images: [
        {
            src: '/images/products/Analyzers/CVM-E3-Mini/FO_CVM-E3-MINI_L.jpg',
            alt: 'CVM',
        },
    ],
    description:
        'Three-phase power analyzer (balanced and unbalanced) for mounting on DIN rail, very compact, with measurements in 4 quadrants.',
    highlights: [
        'Backlit touch-screen (capacitive)',
        'Analogue display of instantaneous parameters (power, maximum power reached and cos φ or PF)',
        'Backlit display',
        'Alarm LED indicator',
        'Tariff cost',
        'Operating hour indicator for preventive maintenance'
    ],
    details: [
        'Control application in low- and medium-voltage distribution panels and switchboards where it is necessary to place an analyzer on the DIN rail due to problems of space.',
        'Alarm control. Maximum value, minimum value and programmable delay.',
        'Control of active or reactive energy by impulse output.',
        'Capture of maximum and minimum instantaneous data of electrical parameters measured.',
    ],
    const: tableData = [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Consumption", value: "4 VA" },
        { label: "Frequency", value: "50…60 Hz" },
        { label: "Nominal voltage", value: "207…253 Vc.a." }
    ],

    thirdTableData: [
        { label: "Size (mm) width x height x depth", value: "52.5 x 118 x 74 (mm)" },
        { label: "Envelope", value: "Self-extinguishing V0 plastic" },
        { label: "Differential current measurement", value: "min. 2,5 mm2" },
        { label: "Cable gauge at power supply terminals", value: "1 mm2 (min.)" },
        { label: "Cable gauge at voltage terminals", value: "mín. 1 mm2" },
        { label: "Fastening", value: "DIN rail" },
        { label: "Weight (kg)", value: "0,38" },
    ],

    fourthTableData: [
        { label: "Correction class", value: "IP 30 / Front: IP 40" },
        { label: "Relative humidity (without condensation)", value: "5…95%" },
        { label: "Storage temperature", value: "-10 … +50 ºC" },
        { label: "Working temperature", value: "-5 … +45 ºC" },
    ],

    fifthTableData: [
        { label: "Electrical safety, Maximum height (m)", value: "2000" },
        { label: "Standards", value: "IEC 61010-1, IEC 61010-2-030, IEC 61326-1, IEC 61557-12 , UL94" },
    ],

    sixthTableData: [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Nominal current (In)", value: "…/5 A ó …/1 A" },
        { label: "Phase current measuring range", value: "2…120% de In" },
        { label: "Maximum input current consumption", value: "0,9 VA" },
        { label: "Minimum current measurement", value: "0,2 % In" },
    ],

    seventhTableData: [
        { label: "Installation category", value: "CAT III 300 V" },
        { label: "Input impedance", value: "400 kΩ" },
        { label: "Frequency measuring range", value: "45…65 Hz" },
        { label: "Voltage measuring range", value: "45…65 Hz" },
        { label: "Nominal voltage", value: "300V Ph-N, 520V Ph-Ph" },
        { label: "Maximum input voltage consumption", value: "0,15 VA" },
        { label: "Minimum measurement voltage (Vstart)", value: "11 V Ph-N" },

    ],

    eighthTableData: [
        { label: "Fieldbus (BACnet)", value: "MS/TP" },
        { label: "Fieldbus (ModBus)", value: "RS-485" },
        { label: "Stop bits (BACnet)", value: "1" },
        { label: "Stop bits (ModBus)", value: "1-2" },
        { label: "Parity (BACnet)", value: "non" },
        { label: "Parity", value: "without, even, odd" },
        { label: "Protocol", value: "ModBus/RTU, BACnet" },
        { label: "Speed", value: "ModBus RTU: 9600-19200 bps / BACnet: 9600-19200 bps (38400-57600-115200 bps: Available on devices with software V2.xx)" },
    ],

    ninthTableData: [
        { label: "LED", value: "2 LED" },
        { label: "Keyboard", value: "3 keys" },
        { label: "Display type", value: "LCD Custom COG" },
    ],

    tenthTableData: [
        { label: "Input/output insulation", value: "Optoisolated" },
        { label: "Quantity", value: "1" },
        { label: "Type", value: "NPN Potential-free contact" },
    ],

    eleventhTableData: [
        { label: "Pulse width", value: "30…500 ms (Programmable)" },
        { label: "Type", value: "NPN" },
        { label: "Maximum frequency", value: "16 imp / s" },
        { label: "Maximum current", value: "50 mA" },
        { label: "Maximum voltage", value: "24 Vdc" },
    ],

    twelfthTableData: [
        { label: "Frequency measurement", value: "0.50%" },
        { label: "Phase current measurement", value: "0.5% ± 1 digit" },
        { label: "Reactive energy measurement (kvarh)", value: "Class 2" },
        { label: "Reactive power measurement (kvar)", value: "Class 2" },
        { label: "Apparent power measurement (kVA)", value: "0.5% ±2 digits" },
        { label: "Active energy measurement (kWh)", value: "I < 0.1 In (Class 1) / I > 0.1 In (Class 0.5)" },
        { label: "Active power measurement (kW)", value: "0.5% ±2 digits" },
        { label: "Phase voltage measurement", value: "0.5% ± 1 digit" },
    ],

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVME3() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">CVM-C10-ITF-IN-485-IC2</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">CVM-E3-MINI-ITF-485-IC, Power analyzer</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: M56414.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=M56414" // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="text-lg font-medium text-gray-900">Features</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>


                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">CURRENT MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">VOLTAGE MEASURE CIRCUIT</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">COMMUNICATIONS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL INPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL TRANSISTOR OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eleventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eleventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MEASUREMENT ACCURACY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.twelfthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.twelfthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
