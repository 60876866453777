let tableData;
const product = {
    name: 'RGU-10',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Protection and Control', href: '/Protection' },
    ],
    images: [
        {
            src: '/images/products/Protection/RGU10/RGU10_R.jpg',
            alt: 'CVM',
        },
    ],
    description:
        'Electronic residual current protection relay of the WG series, connected to the external transformers of the WG / WGC series.' +
        'Superimmunized type relay with high frequency currents filtering and high immunity. True RMS measurement (TRMS).',
    highlights: [
    ],
    details: [
    ],
    const: tableData = [
        { label: "Consumption", value: "6 VA" },
        { label: "Frequency", value: "50/60 Hz" },
        { label: "Nominal voltage", value: "110 - 230 V~ (± 20 %)" },
    ],

    secondTableData: [
        { label: "Size (mm) width x height x depth", value: "52.5 x 85 x 67.9 (mm)" },
        { label: "Cable length to be stripped (mm)", value: "7" },
        { label: "Torque setting", value: "0,5 … 0,6 Nm" },
        { label: "Cable gauge at power supply terminals", value: "0.5 … 2 mm2" },
        { label: "Fastening", value: "DIN rail" },
        { label: "Weight (kg)", value: "0,234" },
    ],

    thirdTableData: [
        { label: "Protection class", value: "IP 20 (terminals), IP 41 (front)" },
        { label: "Relative humidity (without condensation)", value: "5 … 95 %" },
        { label: "Ambient temperature", value: "-40 … +85 ºC" },
        { label: "Working temperature", value: "-10 … +50 ºC" },
    ],

    fourthTableData: [
        { label: "Type", value: "Optocoupled" },
        { label: "Maximum power", value: "0,7 W" },
        { label: "Maximum voltage", value: "230 Vca ± 20%" },
    ],

    fifthTableData: [
        { label: "Electrical safety, Maximum height (m)", value: "2000" },
        { label: "Electrical safety, Installation category", value: "CAT III 300V ca, IEC 61010" },
        { label: "Standards", value: "IEC 61008.1, IEC62020, IEC60947-2 Anexo M, IEC 60755" },

    ],

    sixthTableData: [
        { label: "Insulation", value: "Double-insulated electric shock protection class II (IEC 61010-1)" },

    ],

    seventhTableData: [
        { label: "Display type", value: "Backlit LCD" },

    ],

    eighthTableData: [
        { label: "Nominal load in AC", value: "2500 VA" },
        { label: "Nominal current", value: "6 A ~" },
        { label: "Nominal voltage", value: "250 V ~" },
        { label: "Maximum current", value: "10 A ~" },
        { label: "Mechanical life", value: "10 x 106" },
    ],

    ninthTableData: [
        { label: "Type", value: "Type A ultra-immunised" },
        { label: "Sensitivity (I∆n), A", value: "0,03 … 3 / 5-10-30 (Setup)" },
        { label: "Transformer", value: "External, WG/WGC series"}
    ],

    tenthTableData: [
        { label: "Circuit breaker type", value: "Contactor or Circuit Breaker + trigger coil" },
    ]

}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RGU10() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">RGU-10</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">RGU-10, Residual current relay</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: P11941.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=P11941" // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">AC POWER SUPPLY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.secondTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.secondTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ENVIRONMENTAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.thirdTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.thirdTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">EXTERNAL RESET INPUT FOR TRIPPING</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fourthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fourthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">STANDARDS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.fifthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.fifthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">ELECTRICAL SAFETY</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.sixthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.sixthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">USER INTERFACE</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.seventhTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.seventhTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">DIGITAL RELAY OUTPUTS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.eighthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.eighthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                                    <h1 className="text-2xl font-medium text-gray-900">DIFFERENTIAL PROTECTION</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.ninthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.ninthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-2xl font-medium text-gray-900">PROTECTION</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {product.tenthTableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== product.tenthTableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
