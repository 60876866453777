let tableData;
const product = {
    name: 'CEM C20 312 MID',
    price: '$192',
    href: '#',
    breadcrumbs: [
        { id: 1, name: 'Home', href: '/' },
        { id: 2, name: 'Metering', href: '/Metering' },
    ],
    images: [
        {
            src: '/images/products/Metering/CEMC20M/CEMC20M_R.jpg',
            alt: 'CVM',
        },
    ],
    description:
        'CEM-10 & CEM-20 single-phase electrical energy meter, up to 65 A. ' +
        'Built-in LCD display (7 digits) with rotating screen system. Features an optical communication port (OSC system) on the side of the unit' +
        'for installing the communication module (CEM-M). Also features 2 buttons (1 sealable button) for viewing all the measured information.',
    highlights: [
        "MID certification, module B+D (depending on the type)",
        "Class 1 active energy (Class B, in accordance with MID), Class 2 reactive energy",
        "Complies with the EN 50470 (MID European standards) or IEC 62052-11 standards (international standards), depending on the type.",
        "Compact size (2 modules, 36 mm / 4 modules, 72 mm)",
        "Resettable partial meter",
        "1 programmable impulse output, in accordance with DIN 43864",
        "Indicates bad connections on the screen",
        "Energy storage, even in the case of bad connections."
    ],
    details: [
        "Redundant meter for verifying the energy allocated by the energy provider.",
        "Measurement of electrical consumption in buildings, rooms or machinery.",
        "Control of consumption during a determined period.",
        "Energy consumption report sent to a remote system (PLC/BMS).",
        "Display of electrical parameters (V, A, kW, kW·h, PF, etc.), per phase and three-phase."
        ],
    const: tableData = [
        { label: "Size (mm) width x height x depth", value: "70 x 90 x 61 (mm)" },
        { label: "Weight (kg)", value: "0,34" },
    ],


}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CVMC10() {

    return (
        <div className="bg-white">
            <div className="pt-6">
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {product.breadcrumbs.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {product.name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 rounded-lg lg:block">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className=" lg:grid lg:grid-cols-1 lg:gap-y-8" style={{paddingTop: '75px' }}>
                        <div className="aspect-h-2 aspect-w-3 rounded-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">CEM C20 312</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-900 sm:text-1xl">CEM C20 312</h1>
                            <br/>
                            <h1 className="text-1xl tracking-tight text-gray-500 sm:text-1xl">Code: Q22312.</h1>
                            <br/>
                            <a
                                href="https://circutor.com/pdf/pdfdatasheet.php?lang=en&prod=Q22312." // Replace with the actual data sheet URL
                                target="_blank" // Open in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                                className="mt-4 inline-block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                View Datasheet
                            </a>
                        </div>
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:grid-rows-[auto,auto] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description=</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="text-lg font-medium text-gray-900">Display features and interface</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-600">
                                            <span className="text-gray-800">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-900">Application</h1>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.details.map((detail) => (
                                        <li key={detail} className="text-gray-600">
                                            <span className="text-gray-800">{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="text-lg font-medium text-gray-500">SPECIFICATIONS</h1>
                            <br></br>
                            <h1 className="text-2xl font-medium text-gray-900">MECHANICAL CHARACTERISTICS</h1>

                            <table className="mt-4 w-full">
                                <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={row.id} className={`${index !== tableData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                        <td className="py-2 w-1/2 text-sm font-medium text-gray-900">{row.label}</td>
                                        <td className="py-2 w-1/2 text-sm text-gray-900">{row.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
