import '../../../../styles/tailwind.css';

const products = [
    {
        id: 1,
        name: 'SVGm -4WF-069M-400',
        href: '/SVGM4W69M400',
        description: 'Static Var Generator',
        imageSrc: '/images/products/Correction/SVGM4W69M400/SVGM4W69M400_1.png',
        imageAlt: 'SVGm',
    },
    {
        id: 1,
        name: 'SVGm -4WF-050M-400',
        href: '/SVGM4W50M400',
        description: 'Static Var Generator',
        imageSrc: '/images/products/Correction/SVGM4W50M400/SVGM4W50M400_1.png',
        imageAlt: 'SVGm',
    },
    {
        id: 1,
        name: 'SVGm -4WF-020M-400',
        href: '/SVGM4W20M400',
        description: 'Static Var Generator',
        imageSrc: '/images/products/Correction/SVGM4W20M400/SVGM4W20M400_1.png',
        imageAlt: 'SVGm',
    },
    {
        id: 1,
        name: 'AFQm -4WF-100M-400',
        href: '/AFQM4W100M400',
        description: 'Active multifunction filter',
        imageSrc: '/images/products/Correction/AFQM4W100M400/AFQM4W100M400_1.png',
        imageAlt: 'AFQm',
    },
    {
        id: 1,
        name: 'AFQm -4WF-075M-400',
        href: '/AFQM4W75M400',
        description: 'Active multifunction filter',
        imageSrc: '/images/products/Correction/AFQM4W75M400/AFQM4W75M400_1.png',
        imageAlt: 'AFQm',
    },
    {
        id: 1,
        name: 'AFQm -4WF-030M-400',
        href: '/AFQM4W30M400',
        description: 'Active multifunction filter',
        imageSrc: '/images/products/Correction/AFQM4W30M400/AFQM4W30M400_1.png',
        imageAlt: 'AFQm',
    },
    {
        id: 1,
        name: 'computer C6 Wi-Fi',
        href: '/COMPC6',
        description: 'Power factor regulator',
        imageSrc: '/images/products/Correction/COMP-C6/COMP-C6_R1.jpg',
        imageAlt: 'COMPC6',
    },
    {
        id: 2,
        name: 'computer c12 Wi-Fi',
        href: '/COMPC12',
        description: 'Power factor regulator',
        imageSrc: '/images/products/Correction/COMP-C12/COMP-C12_R1.jpg',
        imageAlt: 'COMPC12',
    },
    {
        id: 3,
        name: 'computer Max P&P 6',
        href: '/COMPMAX6',
        description: 'Power factor regulator',
        imageSrc: '/images/products/Correction/COMP-MAX6/COMP-MAX6_R1.jpg',
        imageAlt: 'COMPMAX6',
    },
    {
        id: 4,
        name: 'computer Max P&P 12',
        href: '/COMPMAX12',
        description: 'Power factor regulator',
        imageSrc: '/images/products/Correction/COMP-MAX12/COMP-MAX12_R1.jpg',
        imageAlt: 'CVM-E3-Mini',
    },
    {
        id: 5,
        name: 'computer SMART III 14',
        href: '/COMPMAXSMART',
        description: 'Power factor regulator',
        imageSrc: '/images/products/Correction/COMP-MAX-SMART/COMP-MAX-SMART_R1.jpg',
        imageAlt: 'COMPMAXSMART',
    },
    {
        id: 6,
        name: 'computer SMART III 12',
        href: '/COMPSMART312',
        description: 'Power factor regulator',
        imageSrc: '/images/products/Correction/COMP-SMART312/COMP-SMART312_R1.jpg',
        imageAlt: 'COMPSMART312',
    },
    {
        id: 7,
        name: 'CLZ-FP-44/25-HD',
        href: '/CLZHD4425',
        description: 'Three-phase tubular power capacitor',
        imageSrc: '/images/products/Correction/CLZ-HD-4425/CLZ-HD-44251.jpg',
        imageAlt: 'CLZHD4425',
    },
]

export default function Correction() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{textAlign: "center"}}>Power Factor Correction and Harmonic Filtering</h1>
                <div className="my-6 text-center text-gray-600">
                    Diverse capacitor banks available for power factor correction in low-voltage electrical installations supporting 50 Hz and 60 Hz networks. Prevent surcharges, improve installation capacity. Offers cylindrical and prismatic capacitors, some with Heavy Duty system for enhanced performance in extreme environments. Reactors protect capacitors in high harmonic installations, maintaining their characteristics and longevity without affecting operation. Additionally, static VAR generators and harmonic filters are also provided for further power quality improvement.
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 py-20">
                    {products.map((product) => (
                        <a key={product.id} href={product.href} className="group">
                            <div className="aspect-w-7 aspect-h-5 bg-gray-200 rounded-lg overflow-hidden">
                                <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="object-cover w-full h-full group-hover:opacity-75"
                                />
                            </div>
                            <h3 className="mt-4 text-lg text-gray-900">{product.name}</h3>
                            <p className="mt-1 text-sm font-medium text-gray-700">{product.description}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

